const getQueryKeys = (accessToken: string | undefined) => ({
  allMembers: () => ["members", accessToken || "no-access-token"],
  member: (userId: number | undefined) => [
    ...getQueryKeys(accessToken).allMembers(),
    `${userId || "no-user-id"}`,
  ],
  memberDetails: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "details",
  ],
  memberCommunityPosts: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "community-posts",
  ],
  memberSchedulingPreferences: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "scheduling-preferences-dow",
  ],
  memberBookingStatus: (
    userId: number,
    skipInactiveMemberCheck: boolean,
    skipNoClinicianCheck: boolean
  ) => [
    ...getQueryKeys(accessToken).member(userId),
    "booking-status",
    `skip-inactive-member-check-${String(skipInactiveMemberCheck)}`,
    `skip-no-clinician-check-${String(skipNoClinicianCheck)}`,
  ],
  memberSchedulingOverview: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "scheduling-overview",
  ],
  memberTransferStatus: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "transfer-status",
  ],
  memberAvailableStatus: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "available-status",
  ],
  memberAdminNotes: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "admin-notes",
  ],

  memberClinicians: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "clinicians",
  ],

  compulsions: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "compulsions",
  ],

  exposures: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "exposures",
  ],

  consequences: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "consequences",
  ],

  obsessions: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "obsessions",
  ],

  preventionTips: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "prevention-tips",
  ],

  responsePreventions: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "response-preventions",
  ],

  preventionTipsByExposure: (userId: number, exposureId: string) => [
    ...getQueryKeys(accessToken).member(userId),
    "prevention-tips",
    exposureId,
  ],

  responsePreventionsByExposure: (userId: number, exposureId: string) => [
    ...getQueryKeys(accessToken).member(userId),
    "response-preventions",
    exposureId,
  ],

  responsePreventionsAssigmentsWithExposure: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "response-preventions-assigments-with-exposure",
  ],

  tools: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "tools",
  ],

  toolsByExposure: (userId: number, exposureId: string) => [
    ...getQueryKeys(accessToken).member(userId),
    "tools",
    exposureId,
  ],

  triggers: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "triggers",
  ],

  exerciseSessions: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "exercise-sessions",
  ],

  sosEpisodes: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "sos-episodes",
  ],

  sosEpisodesV2: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "sos-episodes-v2",
  ],

  memberTraumaInterviewForms: (userId: number | undefined) => [
    ...getQueryKeys(accessToken).member(userId),
    "trauma-interview-forms",
  ],

  memberActivationTargetsInterviewForms: (userId: number | undefined) => [
    ...getQueryKeys(accessToken).member(userId),
    "activation-targets-interview-forms",
  ],

  memberFunctionalAssessmentInterviewForms: (userId: number | undefined) => [
    ...getQueryKeys(accessToken).member(userId),
    "functional-assessments-interview-forms",
  ],

  memberInterferingBehaviorInterviewForms: (userId: number | undefined) => [
    ...getQueryKeys(accessToken).member(userId),
    "interfering-behavior-interview-forms",
  ],

  memberClinicalScratchpad: (userId: number | undefined) => [
    ...getQueryKeys(accessToken).member(userId),
    "clinical-scratchpad",
  ],

  memberPastMedication: (userId: number | undefined) => [
    ...getQueryKeys(accessToken).member(userId),
    "past-medication",
  ],
  memberChartContacts: (userId: number) => `linked-chat-channels-${userId}`,
  memberChartChannel: (userId: number) => `chart-chat-channel-${userId}`,
  memberHLOCNotes: (userId: number | undefined) => [
    ...getQueryKeys(accessToken).member(userId),
    "hloc-notes",
  ],
  membersWithForms: () => [
    ...getQueryKeys(accessToken).allMembers(),
    "members-with-forms",
  ],
  memberReferrals: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "referrals",
  ],
  memberClinicalRecommendations: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "clinical-recommendations",
  ],
  memberTreatmentMilestoneLetter: (userId: number | undefined) => [
    ...getQueryKeys(accessToken).member(userId),
    "treatment-milestone-letter",
  ],
  memberTreatmentMilestoneLetterStatus: (userId: number | undefined) => [
    ...getQueryKeys(accessToken).member(userId),
    "treatment-milestone-letter-status",
  ],
  memberFullResponseDocsOutcome: (userId: number | undefined) => [
    ...getQueryKeys(accessToken).member(userId),
    "full-response-docs-outcome",
  ],
  memberHealthcareProviders: (userId: number | undefined) => [
    ...getQueryKeys(accessToken).member(userId),
    "member-healthcare-providers",
  ],
  memberRoiRecords: (userId: number | undefined) => [
    ...getQueryKeys(accessToken).member(userId),
    "roi-records",
  ],
  memberSupportTickets: (userId: number | undefined) => [
    ...getQueryKeys(accessToken).member(userId),
    "member-support-tickets",
  ],
  memberOutboundReferrals: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "outbound-referrals",
  ],
  memberParentalAgreement: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "member-parental-agreement",
  ],
  memberPrimaryOutcomeForm: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "member-primary-outcome-form",
  ],
  memberPrimaryOutcomeFormResults: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "member-primary-outcome-form-results",
  ],
  memberABNConsentForm: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "member-abn-consent-form",
  ],
  memberAssessmentResultsScores: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "member-assessment-result-scores",
  ],

  memberContacts: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "member-contacts",
  ],

  memberJournalEntries: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "journal-entries",
  ],

  memberAssignedJournalTypes: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "assigned-journal-types",
  ],
  memberClinicalReviewedSchedulingFrequency: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "clinical-reviewed-scheduling-frequency",
  ],
  memberReminders: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "member-reminders",
  ],

  memberPromoStatus: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "promo-status",
  ],

  memberICDCodesHistory: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "icd-codes-history",
  ],
  memberFormChangeRequests: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "all-form-change-requests",
  ],
  memberExtrenalReferrals: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "member-external-referrals",
  ],
  memberMedication: (userId: number | undefined) => [
    ...getQueryKeys(accessToken).member(userId),
    "member-medication",
  ],
  memberGoals: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "member-goals",
  ],
  memberCompletedAssignmentsSinceLastSession: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "member-completed-assignments-since-last-session",
  ],
  memberSessionAdherence: (userId: number) => [
    ...getQueryKeys(accessToken).member(userId),
    "member-session-adherence",
  ],
});

export default getQueryKeys;
