import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";

import RequiredIndicator from "./RequiredIndicator";

interface CheckboxGroupProps extends CheckboxProps {
  name: string;
  label?: string | React.ReactNode;
  helperText?: string;
  options: {
    label?: string;
    value: string;
    disabled?: boolean;
    errorMessage?: string;
    warningMessage?: string;
  }[];
  value: string[];
  errorMessage?: string;
  showRequiredIndicator?: boolean;
  row?: boolean;
}

export default function CheckboxGroup({
  name,
  label,
  helperText,
  errorMessage,
  value,
  options,
  showRequiredIndicator = false,
  row = false,
  ...restProps
}: CheckboxGroupProps): JSX.Element {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        {label} {showRequiredIndicator ? <RequiredIndicator /> : null}
      </FormLabel>
      <FormGroup row={row}>
        {options.map((option) => (
          <Grid container key={option.value}>
            <Grid item xs={12}>
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    color="primary"
                    checked={value ? value.includes(option.value) : false}
                    value={option.value}
                    name={name}
                    disabled={option.disabled}
                    {...restProps}
                  />
                }
                label={option.label ?? option.value}
              />
            </Grid>
            {(option.errorMessage || option.warningMessage) && (
              <Grid
                item
                xs={12}
                style={{ marginBottom: 2, marginTop: -6, marginLeft: 24 }}
              >
                <Typography
                  color={option.errorMessage ? "error" : "orange"}
                  variant="subtitle2"
                >
                  * {option.errorMessage || option.warningMessage}
                </Typography>
              </Grid>
            )}
          </Grid>
        ))}
      </FormGroup>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}

      {errorMessage && (
        <FormHelperText
          error={Boolean(errorMessage)}
          style={{ fontSize: "14px" }}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
}
