const getQueryKeys = (accessToken: string | undefined) => ({
  allAppointments: () => ["appointments", accessToken || "unknown"],
  allAppointmentsV2: () => ["appointments-v2", accessToken || "unknown"],
  appointmentByMember: (userId: number | undefined) => [
    ...getQueryKeys(accessToken).allAppointments(),
    `${userId || "unknown"}`,
  ],
  returningMemberAcknowledgementStatus: (appointmentId: number | undefined) => [
    ...getQueryKeys(accessToken).allAppointments(),
    `${appointmentId || "unknown"}`,
    "returning-member-acknowledgement-status",
  ],
  appointmentMetrics: (userId: number | undefined) => [
    "appointment-metrics",
    accessToken || "unknown",
    userId,
  ],
  appointmentQuickView: (appointmentId: number | undefined) => [
    "appointment-quick-view",
    appointmentId || "unknown",
    accessToken,
  ],
  sessionTypeRecommendation: (appointmentId: number | undefined) => [
    "session-type-recommendation",
    appointmentId || "unknown",
    accessToken,
  ],
});

export default getQueryKeys;
